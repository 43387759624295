import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { NotificationCenterProps, NotificationCenterFlexModuleResult } from "./typings";
import { NotificationCenter as NC } from "@shared-ui/retail-travel-agent-notification-center";

export const NotificationCenter = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: NotificationCenterProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as NotificationCenterFlexModuleResult | null;

    /* istanbul ignore if */
    if (!model) {
      return null;
    }

    return (
      <div id={id} data-testid="notification-center">
        <NC />
      </div>
    );
  })
);

export default NotificationCenter;
